<template>
    <div class="view pa24">
        <el-button class="mr10" type="primary" @click="openAdd()"
        >添加箱盒任务
        </el-button
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="pageNum"
                :total="total"
        >
            <template v-slot:table>
                <!--<el-table-column align="center" prop="taskId"  label="序号"/>-->
                <el-table-column
                        prop="taskName"
                        align="center"
                        label="任务名称"

                />
                <el-table-column prop="leader" width="80" align="center" label="责任人"/>
                <el-table-column prop="productBatch"  align="center" label="产品批次号">
                    <template slot-scope="scope">
                        {{scope.row.productBatch|| '---'}}
                    </template>
                </el-table-column>
                <el-table-column prop="boxBatch"  align="center" label="箱标批次号">
                    <template slot-scope="scope">
                        {{scope.row.boxBatch|| '---'}}
                    </template>
                </el-table-column>
                <el-table-column prop="packBatch"  align="center" label="盒标批次号">
                    <template slot-scope="scope">
                        {{scope.row.packBatch|| '---'}}
                    </template>
                </el-table-column>
                <el-table-column prop="num"  align="center" label="数量比例">
                  <template slot-scope="scope">
                       {{scope.row.num|| 0}}个 / {{scope.row.packNum|| 0}}盒 / {{scope.row.boxNum|| 0}}箱
                  </template>
                </el-table-column>
                <el-table-column prop="boxSpecs"  align="center" label="扫码打印比例">
                  <template slot-scope="scope">
                    <span style="color: #000000">{{ scope.row.ratio }}*{{ scope.row.packRatio }}*{{scope.row.boxRatio}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="liablePerson" align="center" label="操作员"/>
<!--                <el-table-column prop="endTime" align="center" label="结束时间"/>-->
                <el-table-column prop="createTime" align="center" width="155px" label="创建时间"/>
                <el-table-column align="center"  prop="state" label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.status|taskStatusFilter(scope.row.postStatus) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="
                          edit(scope.row, true);
                          bEdit = false;
                          editUpdate = true
                        ">编辑
                        </el-button>
                        <customPopconfirm confirm-button-text="确定" k cancel-button-text="取消"
                                          @confirm="del(scope.row.taskId)" icon="el-icon-info" icon-color="red"
                                          title="确定要删除吗？">
                            <el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除
                            </el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :title="diagTitle" :visible.sync="centerDialogVisible" @open="openDialog" @close="close"
                   :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" width="50%"
                   center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model="ruleForm.taskName"></el-input>
                </el-form-item>
                <el-form-item label="责任人" prop="leader">
                    <el-input v-model="ruleForm.leader"></el-input>
                </el-form-item>

                <el-form-item label="产品批次号" prop="productBatch">
                    <el-select v-model="ruleForm.productBatch" placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in proOption"
                                :key="item.lotNumber"
                                :label="item.lotNumber + '---' + item.title+'     '+item.count+'个'"
                                :value="item.lotNumber">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="盒标批次号" prop="packBatch">
                  <el-select v-model="ruleForm.packBatch" placeholder="请选择" style="width: 100%">
                    <el-option
                        v-for="item in pbOption"
                        :key="item.lotNumber"
                        :label="item.lotNumber + '---' + item.title+'     '+  item.count+'个'"
                        :value="item.lotNumber">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="箱标批次号" prop="boxBatch">
                    <el-select v-model="ruleForm.boxBatch" placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in xbOption"
                                :key="item.lotNumber"
                                :label="item.lotNumber + '---' + item.title+'     '+  item.count+'个'"
                                :value="item.lotNumber">
                        </el-option>
                    </el-select>
                </el-form-item>
              <el-form-item label="扫码枪" prop="sacnDeviceId">
                <el-select v-model="ruleForm.sacnDeviceId" placeholder="请选择" style="width: 100%">
                  <el-option
                      v-for="item in deviceList"
                      :key="item.deviceId"
                      :label="item.deviceName + '---' + item.deviceNumber+'     '+  item.liablePerson"
                      :value="item.deviceId">
                  </el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <div class="block">
                        <el-date-picker
                                v-model="ruleForm.startTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                align="right"
                                :picker-options="pickerOptions" style="width: 47%">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <div class="block">
                        <el-date-picker
                                v-model="ruleForm.endTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                align="right"
                                :picker-options="pickerOptionsEnd" style="width: 47%">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="扫码打印比例" prop="boxSpecs">
                  <el-input v-model="ruleForm.ratio" min="0" style="width: 30%;margin-right: 10px" type="number" placeholder="产品" @input="ratioChange">
                    <template slot="append">个产品</template>
                  </el-input>
                  <el-input v-model="ruleForm.packRatio" min="0" style="width: 30%;margin-right: 10px" type="number" placeholder="盒标">
                    <template slot="append">个盒标</template>
                  </el-input>
                  <el-input v-model="ruleForm.boxRatio" min="0" style="width: 30%" type="number" placeholder="箱标" :readonly="true">
                    <template slot="append">个箱标</template>
                  </el-input>
                  <br>
                  <span class="tip">产品和箱标全部扫描完成，剩余箱标将全部打印</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</span>
        </el-dialog>

        <taskHandleDialog ref="taskHandleDialog"></taskHandleDialog>
    </div>

</template>

<script>

    import commonTable from "@/components/common/commonTable";
    import {mapState} from 'vuex';
    import customPopconfirm from "@/components/common/customPopconfirm";
    import taskHandleDialog from "./components/taskHandleDialog"
    import {
        queryPage,
        add,
        deleteById,
        update,
        getTaskNums,
    } from "@/api/printSpecial";
    import {
      getNopageDevice
    }from "@/api/deviceManage";

    let ac;
    export default {
        name: 'taskIndex',
        data() {
            return {
                pageData:{},
                taskHandleData: [],
                taskInfoDialogVisible: false,
                taskDownVisible: false,
                TipsInfo: false,
                isPostDialogVisible: true,
                diagTitle: '添加箱盒任务',
                proValue: '',
                proOption: [],
                pbOption: [],
                xbOption: [],
                deviceList:[],
                //
                tableData: [],
                pageNum: 1, // 当前页
                pageSize: 10, //显示条数
                loading: false, //表格加载
                total: 0, //总条数
                taskList: [],
                centerDialogVisible: false,
                bEdit: false,
                editUpdate: true,
                isUpdate: true,
                fileList: [],
                userInfo: this.$store.state.loginRoot.userInfo,
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }],
                    disabledDate: time => {
                        let endDateVal = this.ruleForm.endTime;
                        if (endDateVal) {
                            return time.getTime() > new Date(endDateVal).getTime();
                        }
                    }
                },
                pickerOptionsEnd: {
                    disabledDate: time => {
                        let beginDateVal = this.ruleForm.startTime;
                        if (beginDateVal) {
                            return (
                                time.getTime() <
                                new Date(beginDateVal).getTime()
                            );
                        }
                    }
                },
                ruleForm: {
                    taskName: '', //任务名称
                    productBatch: '', // 产品批次号
                    packBatch: '', // 盒标批次号
                    boxBatch: '', // 箱标批次号
                    leader: '', // 责任人
                    num: '', // 产品总数
                    boxNum: '', // 箱子总数
                    packNum: '', //包装总数
                    startTime: '',
                    endTime: '',
                    ratio: '',
                    packRatio: '',
                    boxRatio: 1,
                  sacnDeviceId:''
                },
                rules: {
                    taskName: [{required: true, message: "请输入任务名称", trigger: "blur"}],
                    productBatch: [{required: true, message: "请选择产品批次号", trigger: "change"}],
                    packBatch: [{required: true, message: "请选择盒标批次号", trigger: "change"}],
                    boxBatch: [{required: true, message: "请选择箱标批次号", trigger: "change"}],
                  sacnDeviceId: [{required: true, message: "请选择扫码枪", trigger: "change"}],
                    desc: [
                        {required: true, message: '请填写下发信息', trigger: 'blur'}
                    ],
                    deviceId: [
                        {required: true, message: '请选择设备', trigger: ['blur', 'change']}
                    ],
                },
                devOption: [],
                downForm: {
                    taskId: '',
                    deviceId: '', // 设备id
                    postNum: '',
                    postStatus: '',
                    remarks: '',
                },
                downRules: {}
            };
        },
        components: {
            commonTable,
            customPopconfirm,
            taskHandleDialog
        },
        filters: {
            taskStatusFilter(status,postStatus) {
                if (status != null) {
                    if (status == 0) {
                        if (postStatus){
                            return'已下发'
                        } else {
                            return '待开始'
                        }
                    } else if (status == 1) {
                        return '进行中'
                    } else if (status == 2) {
                        return '已完成'
                    } else if (status == 3) {
                        return '过期'
                    }
                }
            }
        },
        computed: {
            ...mapState(['routerArr']),
        },
        watch: {},
        created() {
        },
        mounted() {
            this.queryPage();
            this.getProductBatch();
            this.getPackBatch();
            this.getBoxBatch();
        },
        beforeDestroy() {

        },
        methods: {
            noVisible() {
                this.TipsInfo = false;
                this.isPostDialogVisible = true;
                this.taskDownVisible = false;
                this.downForm = {
                    taskId: '',
                    deviceId: '', // 设备id
                    remarks: '',
                }
            }
            ,
            isVisible() {
                if (this.isPostDialogVisible) {
                    this.isPostDialogVisible = false
                } else {
                    if (!this.downForm.remarks) {
                        return this.$message({
                            message: '请填写下发信息！',
                            type: 'warning'
                        });
                    }
                    this.TipsInfo = false;
                    this.isPostDialogVisible = false;
                    this.taskDownVisible = false
                    //请求任务下发接口
                    console.log(this.downForm)
                    this.distribData();
                }
            },
            async queryPage() {
                let data = {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                };

                try {
                    this.loading = true;
                    const result = await
                        queryPage(data);
                    this.loading = false;
                    const {
                        total,
                        list
                    } = result.data;
                    this.tableData = list;
                    this.total = total;
                } catch (error) {
                    this.loading = false;
                }
            }
            ,
            async getProductBatch() {
                const result = await
                    getTaskNums({
                        type: 2
                    })
                console.log('产品批次', result.data);
                this.proOption = result.data
            }
            ,
            async getBoxBatch() {
                const result = await
                    getTaskNums({
                        type: 3
                    })
                console.log('箱标批次', result.data);
                this.xbOption = result.data
            }
            ,
            async getPackBatch() {
              const result = await
                  getTaskNums({
                    type: 5
                  })
              this.pbOption = result.data
            }
            ,
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.ruleForm.coverImg = "";
            }
            ,

            /**@method 模态框打开时的回调 */
            openDialog() {

            }
            ,
            /**@method 模态框关闭的回调 */
            close() {
                (this.ruleForm = {
                    taskName: '', //任务名称
                    productBatch: '', // 产品批次号
                    packBatch: '', // 产品批次号
                    boxBatch: '', // 箱标批次号
                    leader: '', // 责任人,
                    num: '', // ,
                    boxNum: '', // ,
                    packNum: '', // ,
                    ratio: '', // ,
                    boxRatio: 1, // ,
                    packRatio: '', // ,
                }),
                    (this.fileList = []);
                this.bEdit = false;
                this.diagTitle = '添加任务'
            }
            ,
            setValue(e) {
                const {
                    item: {value},
                } = e;
                this.ruleForm.address = `${value.city}${value.district}${value.business}`;
            }
            ,
            /**@method 编辑
             * @param {Object} val - 当前点击行的值
             */
            edit(val, b) {
                let data = JSON.parse(JSON.stringify(val));
                this.getDevice()
                this.centerDialogVisible = true;
                this.ruleForm = data;
                console.log(this.ruleForm);
                this.bEdit = b;
                this.diagTitle = '编辑箱盒任务'
            }
            ,
            /**@method 添加任务 */
            determine() {
                this.$refs.ruleForm.validate(async (valid) => {
                        if (valid) {
                            try {
                                let data = {
                                    ...this.ruleForm
                                };
                                this.$message({
                                    message: "正在添加",
                                });
                                if (this.editUpdate) {
                                    await update(data);
                                    this.$message({
                                        message: "编辑成功",
                                        type:"success"
                                    });
                                    this.queryPage();
                                    this.getPackBatch();
                                    this.getBoxBatch();
                                    this.getProductBatch();
                                } else {
                                    await add(data);
                                    this.$message({
                                        message: "添加成功",
                                        type:"success"

                                    });
                                    this.queryPage();
                                    this.getPackBatch();
                                    this.getBoxBatch();
                                    this.getProductBatch();
                                }
                                this.centerDialogVisible = false;

                            } catch (error) {
                                this.$message({
                                    message: error.message,
                                    type: "error",
                                });
                            }
                        }
                        else {
                            console.log("error submit!!");
                            return false;
                        }
                    }
                );
            }
            ,
            /**@method 删除
             * @param {String} val - 文章id
             */
            async del(val) {
                try {
                    await deleteById({taskId: val});
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    await this.queryPage();
                    await this.getPackBatch();
                    await this.getBoxBatch();
                    await this.getProductBatch();
                } catch (error) {
                    console.log(error);
                }
            },
            handleSizeChange(num) {
                this.pageSize = num
                this.pageNum = 1
                this.queryPage()
            },
            handleCurrentChange(num) {
                this.pageNum = num
                this.queryPage()
            },
            ratioChange(val) {
                this.ruleForm.packRatio = val
            },
          async getDevice(){
              const param = {type:3}
            getNopageDevice(param).then(response=>{
              this.deviceList = response.data
            })
          },
          openAdd(){
            this.centerDialogVisible=true;
            this.editUpdate = false;
            this.getDevice()
          }
        },
    }
    ;
</script>

<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 180px;
        text-align: center;
    }

    /deep/ .avatar-coverImg .el-upload--text {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-coverImg .avatar-uploader-icon {
        line-height: 100px;
    }

    .select-goods-list {
        margin-bottom: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        transition: all 0.4s;
    }

    .select-goods-list:hover {
        background: #f5f5f6;
        transition: all 0.4s;
        cursor: pointer;
    }


    /deep/ .disabled .el-upload--picture-card {
        display: none;
    }

    .info {
        cursor: pointer;
        color: #113DEE;
    }

/deep/ .disabled .el-upload--picture-card {
display: none;
}
.info{
cursor: pointer;
color: blue;
}

/deep/ .disabled .el-upload--picture-card {
display: none;
}


/deep/ .details .el-dialog__body {
text-align: center;
font-size: 16px
}

/deep/ .details .el-dialog__body div {
line-height: 32px;
}

/deep/ .details .el-dialog__body div span:first-child {
width: 180px;
display: inline-block;
text-align: right;
}

/deep/ .details .el-dialog__body div span:last-child {
width: 180px;
display: inline-block;
text-align: left;
}
.tip{
    color: #f00;
    padding-left: 20px;
}
</style>
